import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/utils/seo"
import BackgroundImage from "../components/Partials/BackgroundImage"
import { get } from "lodash"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import {
    NotFoundPageHeader,
    UnderlinedTextCTA
} from "../components/Partials/TextPieces"
import Spacer from "../components/Partials/Spacer"
import styled from "@emotion/styled"
import { maxW } from "../styles/mediaQueries"

const NotFoundPage = ({ data }) => {
    const { fluid: bkgImg } = get(data, "placeholderImage.childImageSharp", {})
    return (
        <>
            <SEO title="404: Not found" />
            <BackgroundImage bkgImg={bkgImg} />
            <MaxWidthInnerContainer>
                <NotFoundMainContainer>
                    <Spacer size="medium" />
                    <NotFoundPageHeader>
                        We&apos;re sorry we couldn&apos;t find that page
                    </NotFoundPageHeader>
                    <Spacer size="small" />
                    <UnderlinedTextCTA to="/">
                        Return to the home page
                    </UnderlinedTextCTA>
                    <Spacer size="medium" />
                </NotFoundMainContainer>
            </MaxWidthInnerContainer>
        </>
    )
}

export default NotFoundPage

export const query = graphql`
    query {
        allHomeJson {
            nodes {
                hero {
                    copy {
                        header
                        text
                    }
                }
                sections {
                    text
                    header
                }
            }
        }
        placeholderImage: file(
            relativePath: { eq: "background-images/Shell-background01.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const NotFoundMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 40px;
    position: relative;
    max-width: 650px;
    text-align: left;
    min-height: 600px;
    ${maxW[2]} {
        min-height: 400px;
    }
`
